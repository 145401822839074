import * as React from "react"
import "../styles/index.scss"
import Gallery from '@browniebroke/gatsby-image-gallery'
import { graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { Title, Meta } from "react-head";


// markup
const IndexPage = ({ data }) => {
  const images = data.gallery.edges.map(({ node }) => node.childImageSharp)
  console.log(images)
  return (
    <main >
      <Title>Imagine Expedition</Title>
      <Meta name="description" content="This exhibition “Imagine” is a group show from Te Tai Tokerau Tangata Whai Ora Network’s artists, people who have found solace and expression through their artwork." />

      <div className="titles">
        <h1>Imagine Exhibition</h1>
        <h2>Te Tai Tokerau Tangata Whai Ora Network</h2>
      </div>

      <Gallery images={images} imgClass="gallery-images"/>
      <StaticImage src="../images/imagine-poster.webp" alt="Imagine" className="imagine-banner"/>
      <div class="bottom-text">
        Questions or Complaints?
        <br/>
        contact@jmw.nz
      </div>
    </main>
  )
}

export const pageQuery = graphql`
  query ImagesForGallery {
    gallery: allFile(
      filter: { sourceInstanceName: { eq: "gallery" } }
      sort: { fields: id }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(
              layout: FULL_WIDTH
              transformOptions: {
                fit: INSIDE
              }
            )
          }
        }
      }
    }
  }
`


export default IndexPage
